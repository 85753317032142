/*==========  Header Style  ==========*/

.header{
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 32px 0 96px;
  height: 64px;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  background-color: $colorGrey8;
  z-index: 6;
  &-logo{
    line-height: 1.2em;
    margin-right: 35px;
    color: #000000;
  }
  &-nav{
    margin-right: auto;
    align-self: flex-end;
    li{
      display: inline-block;
      margin-right: 32px;
      color: $colorGrey10;
      font-weight: 500;
      font-size: 18px;
      cursor: pointer;
      transition: $transition;
      padding-bottom: 14px;
      border-bottom: 2px solid rgba(1,1,1,0);
      &:last-of-type{
        margin-right: 0;
      }
      &.is-active{
        color: $color1;
        border-color: $color1;
      }
    }
  }
  &-rg{
    margin-left: 35px;
    > *{
      margin-right: 8px;
      &:last-child{
        margin-right: 0;
      }
    }
  }
  &.bg-white{
    background-color: #FFFFFF;
  }
  &.br-bottom{
    border-bottom: 1px solid #E6E6E6;
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .header-nav li:hover{
    color: $color1;
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){
  .header{
    &-logo{
      width: 135px;
    }
  }
}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




