/*========== Profile Style  ==========*/
.lab-dash{
  &-block{
    border-radius: 16px;
    padding: 24px;
    background-color: #FFFFFF;
    &.mb-bt{
      margin-bottom: 16px;
    }
  }
  &-title{
    &.mb-bt{
      margin-bottom: 15px;
    }
  }
  &-image-user{
    width: 190px;
    height: 240px;
    overflow: hidden;
    border-radius: 16px;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 16px;
    }
    &.size-two{
      width: 185px;
      height: 200px;
    }
    &.size-three{
      width: 135px;
      height: 135px;
    }
  }
}

.education-item{
  margin-bottom: 24px;
  .title{
    line-height: 1.6em;
    margin-bottom: 4px;
    .badge{
      display: inline-block;
      margin-left: 8px;
      line-height: 1.3em;
    }
    span{
      vertical-align: middle;
    }
  }
  &:last-of-type{
    margin-bottom: 0;
  }
}

.experience-item{
  padding-bottom: 24px;
  border-bottom: 1px solid #E6E6E6;
  margin-bottom: 24px;
  &:last-of-type{
    padding-bottom: 0;
    border-bottom: none;
    margin-bottom: 0;
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




