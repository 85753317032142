/*==========  Form Style  ==========*/

input::-webkit-input-placeholder,
textarea::-webkit-input-placeholder {
	color: #222;
	opacity: 1;
}

input:-moz-placeholder,
textarea:-moz-placeholder {
	color: #222;
	opacity: 1;
}

input::-moz-placeholder,
textarea::-moz-placeholder {
	color: #222;
	opacity: 1;
}

input:-ms-input-placeholder,
textarea:-ms-input-placeholder {
	color: #222;
	opacity: 1;
}

input, textarea, select {
	border-radius: 0;
	background: none;
	border: none;
	margin: 0;
	width: 100%;
	padding: 0;
}

textarea {
	display: block;
	resize: vertical;
	overflow: auto;
}

select::-ms-expand {
	display: none;
}

.invalid {
	border-color: red;
	color: red;
}

/*==========  Text fields  ==========*/

.input-error{
	//display: none;
	margin-top: 5px;
	color: #FA665B;
	font-size: 14px;
}

.input {
	font-size: 14px;
	font-weight: 400;
	color: $color3;
	height: 48px;
	background-color: $colorGrey6;
	padding: 0 12px 0 12px;
	border-radius: 6px;
	outline: none;
	transition: 0.24s;
	&::placeholder{
		font-size: 14px;
		color: $colorGrey7;
	}
	&:focus{
		box-shadow: 0px 0px 0px 2px $color1;
	}
	&:disabled,
	&.dispbled{
		pointer-events: none;
		color: $colorGrey3;
	}
	&.md{
		height: 40px;
	}
	&.sm{
		height: 34px;
	}
	&.error{
		box-shadow: 0px 0px 0px 1.5px  #FA665B;
		+ .input-error{
			display: block;
		}
	}
	&.light{
		background-color: #FFFFFF;
		&::placeholder{
			color: #A8A8A8;
		}
	}
}

textarea.input {
	height: 190px;
	padding-top: 15px;
	line-height: 18px;
	min-height: 100px;
	&.tx-md{
		height: 80px;
	}
}

.input-wrapper-material {
	position: relative;
}

.input-wrapper-material{
	width: 100%;
	.input{
		padding: 22px 12px 8px 12px;
	}
	.input-inner{
		position: relative;
	}
	&.textarea{
		label{
			top: 22px;
		}
	}
	label {
		padding: 0 5px;
		font-size: 14px;
		color: $colorGrey7;
		left: 7px;
		top: 50%;
		transform: translateY(-50%);
		white-space: nowrap;
		overflow: hidden;
		position: absolute;
		pointer-events: none;
		display: -webkit-box;
		-webkit-box-orient: vertical;
		-webkit-line-clamp: 1;
		max-width: calc(100% - 18px);
		transition: 0.32s;
	}
	&.dispbled{
		pointer-events: none;
		label{
			color: #A8A8A8;
		}
		.input{
			color: $colorGrey3;
		}
	}
	&.error{
		label{
			color: $colorGrey7!important;
		}
		.input{
			box-shadow: 0px 0px 0px 1.5px  #FA665B!important;
		}
		.input-error{
			display: block;
		}
	}
	&.focus-st{
		.input:focus + label{
			top: 14px;
			font-size: 12px;
			color: $color1;
		}
	}
}
.input-wrapper-material .input-typed.typed + label,
.input-wrapper-material.typed label,
.input-wrapper-material.focus label {
	top: 14px;
	font-size: 12px;
}
.input-wrapper-material.focus label{
	color: $color1;
}

.input-wrapper-icon{
	position: relative;
	.input-inner{
		position: relative;
	}
	&.clean{
		.input{
			padding-right: 36px;
		}
	}
	.input{
		padding-left: 36px;
	}
	.icon{
		position: absolute;
		top: 50%;
		transform: translateY(-45%);
		left: 12px;
		display: inline-block;
		width: 16px;
		line-height: 0;
		img{
			width: 100%;
		}
	}
	.icon-clean{
		position: absolute;
		top: 50%;
		transform: translateY(-45%);
		right: 14px;
		display: inline-block;
		width: 14px;
		line-height: 0;
		background-color: rgba(1,1,1,0);
		cursor: pointer;
		outline: none;
		img{
			width: 100%;
		}
	}
	&.wh-incon{
		.input{
			padding: 0 12px;
		}
	}
}

.input-wrapper-simple{
	position: relative;
	.input-inner{
		position: relative;
	}
	.input{
		padding: 0 12px;
	}
}

.form-group{
	.ml-select-wrapper-material,
	.sl-select-wrapper-material,
	.input-wrapper-material{
		margin-bottom: 16px;
	}
	.checkbox-entry{
		margin-bottom: 20px;
	}
	&.md{
		.ml-select-wrapper-material,
		.sl-select-wrapper-material,
		.input-wrapper-material{
			margin-bottom: 8px;
		}
	}
}

/*==========  Checkbox Style  ==========*/

.checkbox-entry {
	display: inline-block;
	cursor: pointer;
	margin: 0;
	padding: 0;
	min-width: 30px;
	min-height: 20px;
	transition: $transition;
	input {
		display: none;
	}

	span {
		position: relative;
		font-size: 14px;
		line-height: 22px;
		padding-left: 22px;
		display: block;
		transition: $transition;
		color: #1A1A1A;
	}

	span:before {
		content: "";
		position: absolute;
		left: 0;
		top: 2px;
		width: 16px;
		height: 16px;
		border: 1.5px $colorGrey5 solid;
		border-radius: 4px;
		transition: $transition;
		background-color: #fff;
	}

	span:after {
		content: "";
		position: absolute;
		left: 7px;
		top: 6px;
		width: 4px;
		height: 7px;
		z-index: 1;
		backface-visibility: hidden;
		transition: $transition;
		opacity: 0;
		visibility: hidden;
		border-right: 2px solid #fff;
		border-bottom: 2px solid #fff;
		transform: rotate(45deg);
	}
	input:checked + span:before {
		border-color: $color1;
		background-color: $color1;
	}
	input:checked + span:after {
		opacity: 1;
		visibility: visible;
	}

	input[type=radio] + span:before,
	input[type=radio] + span:after{
		border-radius: 50%;
	}
	input[type=radio] + span:after{
		border: none;
		background-color: #FFFFFF;
		width: 6px;
		height: 6px;
		top: 7px;
		left: 5px;
	}
	&.disable{
		pointer-events: none;
		span:before{
			background-color: $colorGrey2;
		}
	}
	&.disable-checked{
		pointer-events: none;
		input:checked + span:after{
			border-color: $colorGrey3;
		}
		input:checked + span:before {
			border-color: $colorGrey2;
			background-color: rgba(1,1,1,0);
		}
		input[type=radio] + span:after{
			background-color: $colorGrey2;
		}
	}
	&.redonly{
		cursor: default;
		input{
			pointer-events: none;
		}
	}
}
.checkbox-wrapper{
	.checkbox-entry{
		display: block;
		margin-bottom: 8px;
		&:last-of-type{
			margin-bottom: 0;
		}
	}
}

.range-slider{
	display: flex;
	align-items: center;
	&-value{
		padding: 6px 12px;
		background-color: rgba(0, 0, 0, 0.04);
		border-radius: 8px;
		color: $color3;
		margin-left: 8px;
	}
}

.range-slider-slide {
	-webkit-appearance: none;
	height: 5px;
	border-radius: 8px;
	background: rgba(0, 0, 0, 0.08);
	outline: none;
	padding: 0;
	margin: 0;
	border-color: red;
	// Range Handle
	&::-webkit-slider-thumb {
		appearance: none;
		width: 10px;
		height: 10px;
		border-radius: 50%;
		background: #7161F3;
		border: none;
		cursor: pointer;
		transition: background .15s ease-in-out;
	}
	&:active::-webkit-slider-thumb {
		background: #7161F3;
	}

	&::-moz-range-thumb {
		width: 10px;
		height: 10px;
		border: 0;
		border-radius: 50%;
		background: #7161F3;
		cursor: pointer;
		transition: background .15s ease-in-out;
	}

	&:active::-moz-range-thumb {
		background: #7161F3;
	}

	// Focus state
	//&:focus {
	//
	//	&::-webkit-slider-thumb {
	//		box-shadow: 0 0 0 3px $shade-0,
	//		0 0 0 6px $teal;
	//	}
	//}
}

/*==========  Input (only placeholder) ==========*/

.input-pl{
	border: none;
	outline: none;
	&-wrapper{
		position: relative;
		.icon{
			display: inline-block;
			width: 24px;
			line-height: 0;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			svg{
				max-width: 100%;
			}
		}
		&.icon{
			.input-pl{
				padding-left: 32px;
			}
		}
	}
	&::placeholder{
		color: $colorGrey3;
	}
	&.sm{
		font-size: 14px;
	}
	&.xl{
		font-size: 24px;
		font-weight: 500;
	}
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
	.input:hover{
		background-color: $colorGrey6-2;
	}
	.checkbox-entry:hover{
		opacity: 0.8;
	}
}


/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px)

// @media (max-width: 1199px)

// @media (max-width: 991px)

// @media (max-width: 768px)

// @media (max-width: 575px)



