@import 'variables';

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* GLOBAL SETTINGS */
/*-------------------------------------------------------------------------------------------------------------------------------*/

html {
	height: auto;
}

body {
	height: auto;
	overflow: visible;
	background-color: $colorGrey8;
}

body ::selection {
	color: #fff;
	background: #000;
}

body ::-moz-selection {
	color: #fff;
	background: #000;
}

#content-block{
	min-height: 100vh;
	&.sp-left{
		padding-left: 64px;
	}
	&.sp-top{
		padding-top: 90px;
	}
	&.sp-no{
		padding: 0!important;
	}
}

/*-------------------------------------------------------------------------------------------------------------------------------*/
/* OTHERS STYLES */
/*-------------------------------------------------------------------------------------------------------------------------------*/

@import "fonts";
@import "reset";
@import "common";
@import 'typography' ;
@import 'buttons';
@import 'header';
@import "aside-nav";
@import "profile";
@import "tabs";
@import "pop-up";
@import "multiselect";
@import "chat";
@import "other-elements";

@import "swiper";
@import "form";
@import 'transitions';


html {
	height: auto;
}
