/*==========  CHAT MAIN WINDOW  ==========*/
.lb-w-chat{
	background-color: #fff;
	border-radius: 16px;
	position: relative;
	overflow: hidden;
	&-header{
		display: flex;
		align-items: center;
		justify-content: space-between;
		padding: 12px 24px;
		border-bottom: 1px solid rgba(0, 0, 0, 0.08);
		background-color: #fff;
		border-top-left-radius: 16px;
		border-top-right-radius: 16px;
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		height: 72px;
		z-index: 4;
	}
	&-user{
		display: flex;
		align-items: center;
		.image{
			width: 40px;
			height: 40px;
			line-height: 0;
			overflow: hidden;
			border-radius: 50%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
			}
		}
		.info{
			padding-left: 8px;
		}
	}
	&-body{
		padding: 35px 24px 0 24px;
		position: relative;
	}
	&-loader{
		position: absolute;
		left: 0;
		right: 0;
		top: 0;
		padding: 0 15px;
	}
	&-scrolling{
		padding: 90px 0 130px 0;
		overflow-y: auto;
		overflow-x: hidden;
	}
	&-message{
		padding: 8px;
		border-top-right-radius: 8px;
		border-top-left-radius: 8px;
		border-bottom-right-radius: 8px;
		background-color: rgba(0, 0, 0, 0.04);
		position: relative;
		margin-left: 12px;
		display: inline-block;
		min-width: 100px;
		max-width: 50%;
		.text-in{
			font-size: 14px;
			line-height: 1.2em;
			color: #161616;
			margin-bottom: 4px;
		}
		.time{
			font-size: 13px;
			line-height: 1.2em;
			color: #767676;
			text-align: right;
			position: relative;
			.icon{
				display: none;
				position: absolute;
				width: 18px;
				line-height: 0;
				right: 0;
				top: 4px;
				img{
					width: 100%;
				}
			}
		}
		.arrow{
			position: absolute;
			width: 12px;
			height: 12px;
			bottom: 0;
			right: 100%;
			overflow: hidden;
			color: rgba(0, 0, 0, 0.04);
			&:after{
				content: "";
				display: block;
				width: 50px;
				height: 50px;
				border-radius: 30%;
				box-shadow: 14px 1px 0 0 currentColor;
				position: absolute;
				right: 0px;
				bottom: 0;
			}
		}
	}
	&-message-wrap{
		display: flex;
		justify-content: flex-start;
		&.own-message{
			justify-content: flex-end;
			.lb-w-chat-message{
				border-bottom-left-radius: 8px;
				border-bottom-right-radius: 0;
				background-color: rgba(94, 75, 241, 0.12);
				margin-left: 0;
				margin-right: 12px;
				.time{
					//padding-right: 22px;
					.icon{
						//display: inline-block;
					}
				}
				.arrow{
					right: auto;
					left: 100%;
					color: rgba(94, 75, 241, 0.12);
					&:after{
						box-shadow: -14px 1px 0 0 currentColor;
						right: auto;
						left: 0;
					}
				}
			}
		}
	}
	&-input{
		padding: 18px 16px;
		position: absolute;
		left: 0;
		bottom: 0;
		right: 0;
		background-color: #ffffff;
		border-bottom-right-radius: 16px;
		border-bottom-left-radius: 16px;
		z-index: 4;
		.input-message{
			font-size: 14px;
			font-weight: 400;
			color: #161616;
			min-height: 48px;
			background-color: rgba(0, 0, 0, 0.04);
			padding: 14px 180px 14px 14px;
			border-radius: 6px;
			outline: none;
			transition: 0.24s;
			&:focus{
				box-shadow: 0px 0px 0px 2px #5E4BF1;
			}
			&:empty::before {
				content: attr(placeholder);
				color: #767676;
				pointer-events: none;
				display: block;
			}
		}
		.input-wr{
			position: relative;
		}
	}
	&-btn{
		display: flex;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		right: 0;
		padding: 8px 16px 8px 0;
		z-index: 8;
		> *{
			margin-left: 8px;
			&:first-child{
				margin-left: 0;
			}
		}
	}
	&-file{
		margin-bottom: 5px;
		.upload-die{
			width: 100%;
		}
	}
	&-file-ms{
		display: flex;
		align-items: center;
		margin-bottom: 4px;
		font-size: 14px;
		transition: all .34s ease-out;
		.name{
			font-size: 16px;
			color: #161616;
			margin-bottom: 2px;
		}
		.icon{
			border-radius: 50%;
			background-color: rgba(94, 75, 241, 0.12);
			width: 40px;
			flex: 0 0 40px;
			height: 40px;
			position: relative;
			margin-right: 8px;
			img{
				width: 100%;
				max-width: 24px;
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%, -50%);
			}
		}
		.size{
			font-size: 12px;
			> a,
			> span{
				display: inline-block;
				vertical-align: middle;
			}
			.btn{
				font-size: 12px;
			}
		}
	}
	&-image-ms{
		max-width: 200px;
		.image-link{
			display: inline-block;
			line-height: 0;
			transition: all 0.28s ease-out;
		}
		img{
			max-width: 100%;
		}
	}
}
.lb-w-chat-message-wrap + .lb-w-chat-message-wrap{
	margin-top: 2px;
}

/*==========  EMOJI  ==========*/
.drop-emoji{
	display: inline-block;
	position: relative;
	&-wrap{
		background-color: #fff;
		border-radius: 10px!important;
		padding: 6px;
		position: absolute;
		bottom: calc(100% + 6px);
		right: 0;
		border: 1px solid rgba(0, 0, 0, 0.04)!important;
		flex-wrap: wrap;
		width: 134px;
		filter: drop-shadow(0px 6px 8px rgba(0, 0, 0, 0.08));
		z-index: 10;
		&:after{
			content: '';
			display: block;
			width: 12px;
			height: 13px;
			background-color: #fff;
			position: absolute;
			bottom: -4px;
			right: 2px;
			transform: translateX(-50%) rotate(45deg);
		}
		.emoji-mart-anchor{
			color: #6F6F6F;
			outline: none;
		}
		.emoji-mart-search {
			display: none;
		}
		.emoji-mart-category-label{
			color: #161616;
			font-size: 15px;
			font-weight: 400;
		}
		.emoji-mart-bar-preview{
			display: none;
		}
	}
	&-overlay{
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		background-color: rgba(1,1,1,0);
		z-index: 6;
	}
}

/*==========  POP-UP (confirm)  ==========*/
.lb-w-chat{
	&-confirm{
		position: fixed;
		top: 100px;
		left: 100px;
		z-index: 44;
		background-color: #fff;
		padding: 24px;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		border-radius: 16px;
		box-shadow: 0px 4px 80px rgb(0 0 0 / 12%);
		.user-image{
			width: 80px;
			height: 80px;
			flex: 0 0 80px;
			border-radius: 50%;
			margin-bottom: 24px;
			position: relative;
			background-color: #fff;
			&:before {
				content: '';
				position: absolute;
				display: block;
				width: 100%;
				height: 100%;
				left: 0;
				top: 0;
				background-color: #02BB9F;
				border-radius: 50%;
				animation: pulse-ring 1.25s cubic-bezier(0.215, 0.61, 0.355, 1) infinite;
				z-index: -1;
			}
			&.no-animation{
				&:before{
					display: none;
				}
			}
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
				position: relative;
				z-index: 1;
			}
		}
	}
}
/*==========  POP-UP (Video chat)  ==========*/
.lb-w-chat{
	&-pop-up{
		//position: absolute;
		//top: 0;
		//left: 0;
		//right: 0;
		//bottom: 0;
		//z-index: 4;
		//background-color: #fff;
		//padding: 16px 16px 32px 16px;
		//display: flex;
		//flex-direction: column;
		//align-items: center;
		//justify-content: space-between;
		.navigation-top{
			text-align: center;
			margin-bottom: 24px;
		}
		.navigation-bottom{
			padding: 16px;
			border-radius: 16px;
			width: 100%;
			max-width: 530px;
			background-color: rgba(0, 0, 0, 0.04);
			display: flex;
			align-items: center;
			justify-content: center;
			.v-mr-24{
				margin-right: 24px;
			}
			.v-mr-8{
				margin-right: 8px;
			}
		}
		&-inner{
			position: absolute;
			top: 0;
			left: 0;
			right: 0;
			bottom: 84px;
			z-index: 4;
			background-color: #fff;
			padding: 50px 16px 32px 16px;
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: space-between;
			&.hidden-pop-up{
				display: none;
				visibility: hidden;
			}
		}
		&-btn{
			position: absolute;
			z-index: 22;
			top: 0;
			left: 50%;
			transform: translate(-50%);
			padding: 20px;
			//background: #fff;
			justify-content: center;
		}
	}
	&-window{
		padding: 16px;
		border-radius: 16px;
		width: 100%;
		max-width: 530px;
		background-color: rgba(0, 0, 0, 0.04);
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		align-items: center;
		height: 10000px;
		max-height: 375px;
		margin-bottom: 24px;
		position: relative;
		overflow: hidden;
		.nv-top{
			display: flex;
			justify-content: space-between;
			align-items: center;
			width: 100%;
			margin-bottom: 24px;
		}
		.nv-bottom{
			display: flex;
			justify-content: space-between;
			align-items: flex-end;
			width: 100%;
			margin-top: 24px;
		}
		.name{
			font-size: 14px!important;
		}
		.user-image{
			width: 120px;
			height: 120px;
			flex: 0 0 120px;
			overflow: hidden;
			border-radius: 50%;
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
				border-radius: 50%;
			}
		}
		.video-chat-subscriber{
			position: absolute;
			left: 0;
			top: 0;
			width: 100%;
			height: 100%;
			border-radius: 16px;
			overflow: hidden;
		}
	}
	&-pop-up{
		.lb-w-chat-video{
			display: none;
		}
		&.video-chat{
			.nv-bottom{
				.badge{
					background-color: #fff;
					color: #161616;
					z-index: -3;
				}
			}
			.btn-cr-icon{
				background-color: #fff;
			}
			.user-image{
				position: relative;
				z-index: -3;
			}
			.lb-w-chat-video{
				display: block;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				z-index: -1;
			}
		}
	}
}

/*==========  notifications-message  ==========*/
.notifications-message{
	position: fixed;
	top: 24px;
	right: 24px;
	border-radius: 16px;
	box-shadow: 0px 4px 60px rgb(0 0 0 / 15%);
	background-color: #fff;
	padding: 16px 16px 16px 56px;
	z-index: 44;
	max-width: 350px;
	min-width: 250px;
	.icon{
		position: absolute;
		width: 32px;
		line-height: 0;
		top: 50%;
		transform: translateY(-50%);
		left: 16px;
		img{
			max-width: 100%;
		}
	}
	.name{
		margin-bottom: 2px;
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 1;
		-webkit-box-orient: vertical;
	}
	.text-message{
		display: -webkit-box;
		overflow: hidden;
		-webkit-line-clamp: 2;
		-webkit-box-orient: vertical;
	}
}

@keyframes pulse-ring {
	0% {
		transform: scale(1);
		opacity: 0.6;
	}
	80%, 100% {
		transform: scale(1.3);
		opacity: 0;
	}
}
.audio-chat-publisher{
	display: none;
}
/* Button */
.btn.btn-cr-icon{
	padding: 4px;
	border-radius: 6px;
	background-color: rgba(1, 1, 1, 0.04);
	line-height: 0;
	transition: all 0.34s ease-out;
	&:active {
		transform: scale(0.98);
	}
	.icon{
		display: inline-block;
		width: 24px;
		line-height: 0;
		img{
			max-width: 100%;
		}
	}
	.icon.on{
		display: none;
	}
	.icon.off{
		display: inline-block;
	}
	&.line-through{
		.icon.on{
			display: inline-block;
		}
		.icon.off{
			display: none;
		}
	}
	&.red{
		background-color: #FA665B;
	}
}
/* Hover */
@media (min-width: 1200px){
	.btn.btn-cr-icon:not(.red):hover{
		background-color: rgba(1, 1, 1, 0.08);
	}
	.btn.btn-cr-icon.red:hover{
		background-color: #e6574c;
	}
	.video-chat .lb-w-chat-window .btn.btn-cr-icon:not(.red):hover{
		background-color: rgb(230 230 230);
	}
}

/* Hover */
@media (min-width: 1200px){
	.lb-w-chat-image-ms .image-link:hover{
		opacity: 0.9;
	}
}
