/*==========  Autocomplete Style  ==========*/
.autocomplete{
  &-wrap{
    position: relative;
  }
  &-inner{
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
    z-index: 4;
    padding: 12px 8px;
    li{
      padding: 7px 8px;
      cursor: pointer;
      border-radius: 6px;
      transition: $transition;
    }
  }
  &-title{
    font-size: 12px;
    color: #767676;
    padding: 4px 8px;
    .title{
      span{
        vertical-align: middle;
      }
      .icon-in{
        display: inline-block;
        width: 14px;
        margin-left: 3px;
        line-height: 0;
        img{
          width: 100%;
        }
      }
    }
  }
}

.autocomplete-two{
  &-wrap{
    position: relative;
  }
  &-inner{
    position: absolute;
    top: calc(100% + 8px);
    left: 0;
    right: 0;
    width: 100%;
    background-color: #FFFFFF;
    border-radius: 8px;
    filter: drop-shadow(0px 4px 16px rgba(0, 0, 0, 0.16));
    z-index: 4;
    padding: 12px 8px;
    li{
      padding: 7px 8px;
      cursor: pointer;
      border-radius: 6px;
      transition: $transition;
    }
  }
}

/*==========  Skills-edit Style  ==========*/
.skills-edit-item{
  border-radius: 8px;
  border: 1px solid #E6E6E6;
  padding: 16px;
  margin-bottom: 16px;
  &:last-of-type{
    margin-bottom: 0;
  }
  &-name{
    display: inline-block;
    margin-right: 4px;
  }
}

/*==========  Progress-bar Style  ==========*/
.progress{
  &-wrapper{
    display: block;
    width: 100%;
    height: 8px;
    position: relative;
    border-radius: 8px;
    background-color: $colorGrey6-2;
    &.type-2{
      height: 4px;
    }
  }
  &-inner{
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    border-radius: 8px;
    background-color: $color2;
    &.orang{
      background-color: #FFAF3C;
    }
    &.red{
      background-color: #FA665B;
    }
  }
}

/*==========  Badge Style  ==========*/
.badge{
  background-color: rgba(0, 0, 0, 0.04);
  border-radius: 8px;
  padding: 7px 8px;
  color: #AAAAAA;
  font-weight: 500;
  font-size: 14px;
  display: inline-block;
  &.sm{
    font-size: 16px;
    padding: 2px 6px;
    font-weight: 400;
    border-radius: 6px;
  }
  &-wrapper{
    margin-bottom: -8px;
    > .badge{
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }
  &.checkbox-item{
    position: relative;
    overflow: hidden;
    display: inline-flex;
    cursor: pointer;
    transition: $transition;
    z-index: 2;
    input{
      display: none;
    }
    > span{
      transition: $transition;
    }
    > span:after{
      content: '';
      display: block;
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: #ece9fd;
      z-index: -1;
      top: 0;
      left: 0;
      visibility: hidden;
      opacity: 0;
    }
    input:checked + span{
      color: #5E4BF1;
    }
    input:checked + span:after{
      visibility: visible;
      opacity: 1;
    }
  }
  &.btn{
    cursor: pointer;
    transition: $transition;
  }
}
.badge-sm{
  padding: 2px 8px;
  font-size: 16px;
  border-radius: 16px;
  background-color: #E9E9E9;
}
.badge-orang{
  padding: 2px 5px;
  color: #DE5F04;
  background-color: rgba(222, 95, 4, 0.08);
  border-radius: 5px;
  font-size: 12px;
}
.badge-dark{
  padding: 2px 5px;
  background-color: #090822;
  color: #FFFFFF;
  border-radius: 5px;
  font-size: 12px;
  &-wrap{
    .badge-dark{
      margin-right: 4px;
    }
  }
}

.badge-green{
  display: inline-block;
  padding: 4px 6px;
  color: #fff;
  background-color: #02BB9F;
  border-radius: 6px;
  font-size: 12px;
}

.badge-ml{
  display: inline-block;
  padding: 4px 6px;
  border-radius: 6px;
  font-size: 14px;
  line-height: 1.2em;
  &.bg-grey{
    background-color: $colorGrey6-2;
  }
  &.text-sm{
    font-size: 12px;
  }
  &.badge-icon{
    span{
      vertical-align: middle;
    }
  }
  .icon{
    display: inline-block;
    line-height: 0;
    margin-right: 4px;
    width: 17px;
    img{
      width: 100%;
    }
  }
  &-wrapper{
    margin-bottom: -4px;
    .badge-ml{
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }
  &.cirle{
    border-radius: 50%;
    min-width: 22px;
    text-align: center;
  }
  &.bg-red{
    color: #fff;
    background-color: #FA665B;
  }
  &.bg-green{
    color: #fff;
    background-color: #02BB9F;
  }
  &.bg-orang{
    color: #fff;
    background-color: #FFAF3C;
  }
}

/*==========  List Style  ==========*/
.list-style{
  li{
    margin-bottom: 8px;
    padding-left: 24px;
  }
  &.type-a{
    li:not(.not-included){
      position: relative;
      &:after{
        content: "";
        display: block;
        width: 6px;
        height: 10px;
        border-bottom: 2px solid $colorGrey7;
        border-right: 2px solid $colorGrey7;
        transform: rotate(45deg);
        position: absolute;
        top: 4px;
        left: 8px;
      }
    }
    li.not-included{
      position: relative;
      color: #AAAAAA;
      &:after{
        content: "✖";
        display: inline-block;
        position: absolute;
        border: none;
        transform: none;
        color: #AAAAAA;
        height: auto;
        width: auto;
        top: 0px;
        left: 5px;
      }
    }
  }
}

/*==========  Dropdown Style  ==========*/
.dropdown{
  position: relative;
  &:not(.no-arrow){
    .btn{
      padding-right: 32px;
      text-align: left;
      &:after{
        content: "";
        border: 4px solid transparent;
        border-top: 6px solid currentColor;
        position: absolute;
        top: 60%;
        transform-origin: 45% 30%;
        transform: translateY(-50%);
        right: 13px;
        transition: all .24s ease-out;
      }
    }
  }
  &-overlay{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 28;
    display: none;
  }
  &-inner{
    background-color: #fff;
    border-radius: 16px;
    padding: 14px;
    filter: drop-shadow(0px 8px 24px rgba(0, 0, 0, 0.12));
    position: absolute;
    z-index: 32;
    top: calc(100% + 10px);
    left: 0;
    min-width: 180px;
    transition: $transition;
    visibility: hidden;
    opacity: 0;
    transform: translateY(20px);
    font-size: 14px;
    li{
      margin-bottom: 2px;
      border-radius: 8px;
      padding: 2px 10px;
      color: #161616;
      cursor: pointer;
      transition: all 0.24s ease-out;
      display: -webkit-box;
      overflow: hidden;
      line-height: 2.1;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      &.is-active{
        background-color: rgba(94, 75, 241, 0.12)!important;
        color: $color1;
      }
    }
    .in_mx_scroll {
      max-height: 250px;
      overflow-y: auto;
      padding: 0 5px;
      margin: 0 -5px;
    }
  }
  &.checkbox{
    li{
      padding: 0;
    }
    label{
      padding: 7px 10px;
      display: block;
      span{
        line-height: 20px;
        padding-left: 22px;
        color: #161616;
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  &.is-active{
    .dropdown-overlay{
      display: block;
    }
    .dropdown-inner{
      visibility: visible;
      opacity: 1;
      transform: none;
    }
    .btn:after{
      transform: translateY(-50%) rotate(180deg);
    }
    .btn-outlined{
      background-color: $color5;
    }
    .btn-ghost{
      background-color: $color4;
    }
  }
  &.min-a{
    .btn{
      min-width: 135px;
    }
  }
  &.right{
    .dropdown-inner{
      left: auto;
      right: 0;
    }
  }
  &.type-1{
    .dropdown-inner{
      min-width: 200px;
    }
    li{
      text-transform: lowercase;
    }
    .checkbox-entry{
      span{
        display: block;
        &:first-letter{
          text-transform: uppercase;
        }
      }
    }
  }
}

/*==========  Card Style  ==========*/
.card{
  padding: 8px;
  border-radius: 16px;
  background-color: rgba(1,1,1,0);
  transition: $transition;
  display: block;
  text-decoration: none;
  cursor: pointer;
  //height: 100%;
  &-image{
    border-radius: 8px;
    margin-bottom: 16px;
    width: 100%;
    padding-bottom: 60%;
    position: relative;
    overflow: hidden;
    img{
      position: absolute;
      width: 100%;
      height: 100%;
      object-fit: cover;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    }
  }
  &-rating{
    span{
      vertical-align: middle;
    }
    .icon{
      margin-right: 5px;
      display: inline-block;
      width: 20px;
      line-height: 0;
      position: relative;
      top: -1px;
      img{
        width: 100%;
      }
    }
  }
  &-wrap{
    margin-bottom: -24px;
    > [class*=col-]{
      margin-bottom: 16px;
    }
  }
  &-title{
    display: block;
  }
  &-info{
    display: flex;
    justify-content: space-between;
    .tags{
      margin-right: 15px;
    }
  }
  &.type-two{
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
  }
  &.bg-white{
    background-color: #FFFFFF;
  }
  .badge-ml{
    max-width: 100%;
    display: inline-flex;
    align-items: center;
    .icon{
      flex-shrink: 0;
    }
    .link{
      word-wrap: break-word;
      display: block;
      max-width: calc(100% - 17px);
    }
  }
}

/*==========  Accordion Style  ==========*/
.accordion{
  &-item{
    .btn-accordion{
      display: inline-block;
      width: 16px;
      height: 16px;
      line-height: 0;
      margin-left: 5px;
      vertical-align: middle;
      transform: none;
      transition: $transition;
      img{
        width: 100%;
      }
    }
    &.is-active{
      .btn-accordion{
        transform: rotate(180deg);
      }
    }
  }
  &-inner{
    padding-left: 24px;
    //transform  : translateY(0);
    //transition : 150ms transform ease-in;
    //overflow: hidden;
  }
}

/*==========  Users-list Style  ==========*/
.users-list{
  &-item{
    padding: 8px 50px 8px 16px;
    border-radius: 8px;
    transition: $transition;
    cursor: pointer;
    position: relative;
    .image{
      width: 40px;
      height: 40px;
      border-radius: 50%;
      overflow: hidden;
      img{
        border-radius: 50%;
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }
    .name{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $color3;
      margin-bottom: 2px;
      transition: $transition;
    }
    .message{
      display: -webkit-box;
      overflow: hidden;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      color: $colorGrey7;
      transition: $transition;
    }
    .badge-sm{
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      right: 8px;
      background-color: $color4;
      color: $color1;
      max-width: 50px;
      span{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
    &.is-active{
      background-color: $color4;
      .name{
        color: $color1;
      }
      .message{
        color: $color1;
      }
    }
  }
  &-layout{
    background-color: #fff;
    border-radius: 16px;
    padding: 16px 8px 16px 8px;
  }
  &-header{
    padding: 0 8px;
  }
}

.users-list-two{
  li {
    padding: 6px 10px 6px 40px;
    position: relative;
    border-radius: 8px;
    font-size: 16px;
    color: $color3;
    margin-bottom: 4px;
    cursor: pointer;
    transition: $transition;
    &.is-active{
      background-color: $color4!important;
    }
  }
  .image{
    display: inline-block;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    top: 50%;
    left: 8px;
    transform: translateY(-50%);
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  &.mr-r-l{
    margin: 0 -9px;
  }
}

/*==========  Menu-list Style  ==========*/
.menu-list-ms{
  li{
    padding: 10px 12px 10px 48px;
    position: relative;
    font-weight: 500;
    font-size: 16px;
    color: $color3;
    transition: $transition;
    margin-bottom: 8px;
    border-radius: 8px;
    background-color: rgba(1,1,1,0);
    cursor: pointer;
    .icon{
      width: 24px;
      height: 24px;
      line-height: 0;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
      left: 12px;
      img{
        max-width: 100%;
      }
    }
    &.is-active{
      background-color: $colorGrey6-2;
    }
  }
  &.type-two{
    li{
      font-weight: 400;
      padding: 6px 12px 6px 40px;
      margin-bottom: 4px;
      .icon{
        left: 8px;
      }
      .svg-fill{
        fill: $colorGrey7;
      }
      &.is-active{
        background-color: $color4;
        color: $color1;
        .svg-fill{
          fill: $color1;
        }
      }
    }
  }
  &.mr-r-l{
    margin: 0 -10px;
  }
  &_input{
    cursor: default;
    position: relative;
    &.error{
      box-shadow: 0 0 0 2px #fa665b;
    }
    input.input-in{
      height: 20px;
      width: 100%;
      outline: none;
      color: #5E4BF1;
      position: relative;
      z-index: 28;
      &::placeholder{
        color: $colorGrey3;
        font-size: 14px;
        font-weight: 300;
      }
    }
  }
  &_outline{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(1,1,1,0);
    cursor: default;
    z-index: 24;
  }
  &_loading{
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .loader-circle{
      width: 16px;
      position: absolute;
      top: 50%;
      margin-top: -8px;
      right: 10px;
    }
  }
}

/*==========  Block-middle Style  ==========*/
.block-middle{
  span{
    vertical-align: middle;
  }
  .icon{
    display: inline-block;
    line-height: 0;
    width: 18px;
    img{
      width: 100%;
    }
  }
  &.mr-4{
    span{
      margin-right: 4px;
    }
  }
}

/*==========  Tooltips  ==========*/
.tooltips{
  position: absolute;
  background-color: #090822;
  border-radius: 6px;
  padding: 8px 14px;
  color: #fff;
  font-size: 14px;
  display: flex;
  align-items: center;
  line-height: 1.2em;
  top: calc(100% + 15px);
  left: 50%;
  transform: translateX(-50%);
  cursor: default;
  pointer-events: none;
  opacity: 0;
  transition: all .22s ease-out;
  &:after{
    content: "";
    display: block;
    width: 14px;
    height: 14px;
    border-radius: 4px;
    background-color: #090822;
    position: absolute;
    top: -6px;
    left: 50%;
    transform: translateX(-50%) rotate(45deg);
  }
  span{
    display: inline-block;
    margin-right: 15px;
    width: max-content;
    &:last-of-type{
      margin-right: 0;
    }
  }
  &-ms{
    position: relative;
  }
}

/*==========  Timeline  ==========*/
.timeline-item{
  padding-left: 48px;
  padding-bottom: 32px;
  position: relative;
  &:after{
    content: '';
    display: block;
    height: calc(100% + 15px);
    position: absolute;
    top: 0;
    left: 20px;
    border-right: 1px dotted $colorGrey5;
    z-index: 0;
  }
  .image{
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 2;
    img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &:last-of-type{
    padding-bottom: 0;
    &:after{
      display: none;
    }
  }
}

.atc-list-item{
  padding: 6px 12px 6px 12px;
  border-radius: 8px;
  cursor: pointer;
  transition: $transition;
}

/*==========  Thumbnail-add  ==========*/
.thumbnail-add {
  position: relative;
  border-radius: 16px;
  background: rgba(0, 0, 0, 0.04);
  padding-bottom: 100%;
  cursor: pointer;
  transition: $transition;
  &-inner{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    padding: 10px;
    text-align: center;
  }
  .icon{
    display: inline-block;
    width: 24px;
    line-height: 0;
    margin-bottom: 2px;
    img{
      max-width: 100%;
    }
  }
  .title{
    line-height: 1em;
  }
}

/*==========  Thumbnail-coach  ==========*/
.thumbnail-coach{
  border-radius: 16px;
  padding: 16px;
  background-color: #fff;
  .info-users{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;
    .image{
      width: 80px;
      height: 80px;
      flex: 0 0 80px;
      border-radius: 16px;
      overflow: hidden;
      img{
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: 16px;
      }
    }
    .info{
      padding-left: 15px;
      padding-right: 15px;
      flex: 1 1 100%;
      .name{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
      .prof{
        display: -webkit-box;
        overflow: hidden;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
      }
    }
  }
  &-rating{
    .icon{
      margin-right: 5px;
      display: inline-block;
      width: 20px;
      line-height: 0;
      position: relative;
      top: -1px;
      img{
        width: 100%;
      }
    }
    span{
      vertical-align: middle;
    }
  }
  &-wrapper{
    margin-bottom: -15px;
    > [class*=col]{
      margin-bottom: 15px;
    }
  }
}

/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .autocomplete-inner li:hover{
    background-color: $colorGrey6;
  }
  .dropdown-inner li:hover{
    background-color: $colorGrey6-2;
  }
  .card:hover{
    background-color: $colorGrey4;
  }
  .badge.btn:hover{
    opacity: 0.85;
  }
  .users-list-item:hover{
    background-color: $color4;
  }
  .menu-list-ms li:hover{
    background-color: $colorGrey6-2;
  }
  .users-list-two li:hover{
    background-color: $colorGrey6-2;
  }
  .tooltips-ms:hover .tooltips{
    opacity: 1;
    top: calc(100% + 10px);
  }
  .atc-list-item:hover{
    background-color: $colorGrey6-2;
  }
  .thumbnail-add:hover{
    background: rgba(0, 0, 0, 0.08);
  }
}

/*==========  MEDIA QUERIES  ==========*/

@media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}




