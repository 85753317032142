/*==========  Tabs Style  ==========*/
.tabs-nav{
  border-bottom: 1px solid $colorGrey6-2;
  font-size: 16px;
  &.no-br{
    border-bottom: none;
  }
  li{
    border-bottom: 2px solid rgba(1,1,1,0);
    padding: 16px 0;
    margin-right: 24px;
    color: $colorGrey10;
    display: inline-block;
    margin-bottom: -1px;
    cursor: pointer;
    transition: $transition;
    &:last-of-type{
      margin-right: 0;
    }
    .badge-sm{
      margin-left: 8px;
    }
    &.is-active{
      color: $color1!important;
      border-color: $color1!important;
      .badge-sm{
        background-color: rgba(94, 75, 241, 0.12);
      }
    }
  }
  &.type-two{
    li{
      padding-top: 0;
      padding-bottom: 12px;
    }
    .badge-sm{
      margin-left: 6px;
      font-size: 14px;
      padding: 2px 6px;
    }
  }
}
.tabs-container{
  display: none;
  &.is-active{
    display: block;
  }
}
/*==========  Hover  ==========*/

@media (min-width: 1200px){
  .tabs-nav li:hover{
    color: $color3;
    .badge-sm{
      color: $color3;
    }
  }
}

/*==========  MEDIA QUERIES  ==========*/

// @media (max-width: 1368px){}

// @media (max-width: 1199px){}

// @media (max-width: 991px){}

// @media (max-width: 768px){}

// @media (max-width: 575px){}



